<!-- components/Image77.vue -->
<template>
  <picture ref="picture" class="image77">
    <template v-if="isReady">
      <template v-if="hasSrcset">
        <source
          v-if="xl"
          media="(min-width:1441px)"
          :type="getImageType()"
          :srcset="requireSrcset(path + xl, xlTag, webp, v)"
        />
        <source
          v-if="l"
          media="(min-width:1025px)"
          :type="getImageType()"
          :srcset="requireSrcset(path + l, lTag, webp, v)"
        />
        <source
          v-if="m"
          media="(min-width:769px)"
          :type="getImageType()"
          :srcset="requireSrcset(path + m, mTag, webp, v)"
        />
        <source
          v-if="s"
          media="(min-width:480px)"
          :type="getImageType()"
          :srcset="requireSrcset(path + s, sTag, webp, v)"
        />
        <source
          v-if="xxs && xs"
          media="(min-width:376px)"
          :type="getImageType()"
          :srcset="requireSrcset(path + xs, xsTag, webp, v)"
        />
        <source
          v-if="xxs || xs"
          :type="getImageType()"
          :srcset="requireSrcset(path + (xxs || xs), xxs ? xxsTag : xsTag, webp, v)"
        />
      </template>
      <img
        :src="requireImg(path + (xxs || xs || s || m || l || xl))"
        :class="imageClass"
        :alt="alt"
        :width="width"
        :height="height"
        :loading="loading"
        @error="$emit('error')"
        @load="$emit('load', $event)"
      />
    </template>
    <template v-else>
      <img
        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        class="image77-deferred"
        :class="imageClass"
        :alt="alt"
        :width="width"
        :height="height"
        :style="`aspect-ratio: ${width} / ${height}`"
        loading="lazy"
      />
      <noscript ref="noscript"></noscript>
    </template>
    <slot></slot>
  </picture>
</template>

<script setup lang="ts">
import { image77Props, srcsetProps } from '~/constants/sharedProps/image77'

const props = defineProps({
  ...image77Props,
  ...srcsetProps,
})

const onScreen = ref(false)
const noscript = ref<HTMLElement>()
const picture = ref<HTMLElement>()

const isReady = computed(() => !props.defer || onScreen.value)

const checkOnScreen = () => {
  if (isInViewport(picture.value)) {
    window.removeEventListener('scroll', checkOnScreen)
    onScreen.value = true
  }
}

const getImageType = () => {
  return props.webp ? 'image/webp' : 'image/jpeg'
}

onMounted(() => {
  if (props.defer && noscript.value) {
    window.addEventListener('scroll', checkOnScreen)
    // window.defer.then(checkOnScreen) - add defer implementation?

    const img = requireImg(props.path + (props.xxs || props.xs || props.s || props.m || props.l || props.xl))
    noscript.value.innerHTML = `<img src="${img}" class="${props.imageClass}">`
  }
  checkOnScreen()
})

onUnmounted(() => {
  window.removeEventListener('scroll', checkOnScreen)
})
</script>

<style scoped>
.image77 {
  img {
    height: auto;
  }
}
</style>
