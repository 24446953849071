export const image77Props = {
  path: {
    type: String,
    default: '',
  },
  webp: {
    type: Boolean,
    default: false,
  },
  xxl: {
    type: String,
    default: '',
  },
  xl: {
    type: String,
    default: '',
  },
  l: {
    type: String,
    default: '',
  },
  m: {
    type: String,
    default: '',
  },
  s: {
    type: String,
    default: '',
  },
  xs: {
    type: String,
    default: '',
  },
  xxs: {
    type: String,
    default: '',
  },
  imageClass: {
    type: String,
    default: null,
  },
  defer: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: null,
  },
  height: {
    type: String,
    default: null,
  },
  alt: {
    type: String,
    default: 'image holder',
  },
  v: {
    type: Number,
    default: 1,
  },
}

export const srcsetProps = {
  hasSrcset: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: null,
  },
  xlTag: {
    type: String,
    default: 'XL',
  },
  lTag: {
    type: String,
    default: 'L',
  },
  mTag: {
    type: String,
    default: 'M',
  },
  sTag: {
    type: String,
    default: 'S',
  },
  xsTag: {
    type: String,
    default: 'XS',
  },
  xxsTag: {
    type: String,
    default: 'XXS',
  },
}
